<template>
  <div class="zoey">
    <CommonHeader :is-top="true" @openMenu="$emit('openMenu')"></CommonHeader>
    <slot />
  </div>
  
</template>
<script setup>

</script>
<style lang="scss"  scoped>
.zoey{
  color: $zoey-white;
}

</style>
